﻿$(function () {

  // maquillage des formulaires contact et contactvendeur
  if ($('#AuditPage .CONTACTVENDEUR').length === 1) {
    $('.CONTACTVENDEUR').addClass('container');
    $('.CONTACTVENDEUR:eq(1) tr').has('td, th').addClass('row');
    //$('.CONTACTVENDEUR:eq(1) tr').has('th').addClass('row');
    $('th').not('.HeaderQuestion').removeAttr("colspan");//.addClass('col-xs-12');
    $('.HeaderQuestion').addClass('col-xs-5');
    $('.Field').addClass('col-xs-6');
    $('.Information').addClass('col-xs-1');
    $(".Field input:not([type='checkbox']), .Field textarea").addClass('form-control');
    $(".Field textarea").removeAttr('cols');
    $(".Field textarea").removeAttr('rows');
    //$('td[class*="col-"], th[class*="col-"]').css('float', 'none');
    //$('td[class*="col-"], th[class*="col-"]').css('float', 'left');
    $('.validation').removeClass('button').addClass('btn btn-primary');
    $('.Close').removeClass('button').addClass('btn btn-default');
  }

  if ($('#AuditPage .CONTACT').length === 1) {
    $('.CONTACT').addClass('container');
    $('.CONTACT:eq(1) tr').has('td').addClass('row');
    $('.HeaderQuestion').addClass('col-md-4');
    $('.Field').addClass('col-md-6');
    $('.Information').addClass('col-md-2');
    $(".Field input:not([type='checkbox']), .Field textarea").addClass('form-control');
    $(".Field textarea").removeAttr('cols');
    $(".Field textarea").removeAttr('rows');
    $('.validation').removeClass('button').addClass('btn btn-primary');
    $('.Close').removeClass('button').addClass('btn btn-default');
  }
});